import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getToken()) {
      localStorage.removeItem('redirect');
      return true;
    }
    localStorage.setItem('redirect', location.href);
    void this.router.navigate(['/auth/login']);
  }
}
