import * as Rollbar from 'rollbar';
import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';

const rollbarConfig = {
  accessToken: environment.rollbar,
  captureUncaught: true,
  captureUnhandledRejections: true
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: unknown): void {
    this.rollbar.configure({ payload: { environment: environment.production ? 'production' : 'development' } });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.rollbar.error(err as any);
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, NoopAnimationsModule, AppRoutingModule, HttpClientModule],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
