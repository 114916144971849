import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'properties'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'properties',
    canLoad: [AuthGuard],
    loadChildren: () => import('./properties/properties.module').then((m) => m.PropertiesModule)
  },
  {
    path: 'upload',
    canLoad: [AuthGuard],
    loadChildren: () => import('./upload/upload.module').then((m) => m.UploadModule)
  },
  {
    path: 'matching',
    canLoad: [AuthGuard],
    loadChildren: () => import('./matching/matching.module').then((m) => m.MatchingModule)
  },
  {
    path: 'import',
    canLoad: [AuthGuard],
    loadChildren: () => import('./import/import.module').then((m) => m.ImportModule)
  },
  {
    path: 'users',
    canLoad: [AuthGuard],
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
