import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;
  private router: Router;

  constructor(private readonly injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.authService = this.injector.get(AuthService);
    this.router = this.injector.get(Router);
    // eslint-disable-next-line
    let req = request.clone();
    //if (!request.url.includes('/users') || request.url.includes('sign_out')) {
    req = req.clone({
      setHeaders: {
        authorization: `Bearer ${this.authService.getToken() || ''}`
      }
    });
    //}
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    return next.handle(req).pipe(
      // eslint-disable-next-line
      catchError((response: HttpResponse<any>) => {
        if (response && response.status === 401) {
          localStorage.removeItem('token');
          void this.router.navigate(['/auth/login']);
        }
        return throwError(response);
      })
    );
  }
}
