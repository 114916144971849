import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  getToken(): string {
    return localStorage.getItem('token');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  login(email: string, password: string): Observable<HttpResponse<unknown>> {
    return this.http.post<unknown>(
      `${environment.api}users/sign_in`,
      { user: { email, password } },
      { observe: 'response' }
    );
  }

  logOut(): Observable<unknown> {
    localStorage.removeItem('token');
    return this.http.delete<unknown>(`${environment.api}users/sign_out`);
  }

  forgot(email: string): Observable<boolean> {
    return this.http
      .post<boolean>(`${environment.api}users/password`, { user: { email } })
      .pipe(map(() => true));
  }

  reset(token: string, password: string): Observable<unknown> {
    return this.http.put<unknown>(`${environment.api}users/password`, {
      user: {
        password,
        reset_password_token: token,
        password_confirmation: password
      }
    });
  }
}
